import React from "react";
import { Card, Header } from "semantic-ui-react";
import { content } from "../data";
import WorkCardWithModal from "../atoms/WorkCardWithModal";

const TrendingWork = () => {
  return (
    <div style={{ padding: "0.5em" }}>
      <Header size="large" style={{ paddingBottom: "0.75em" }}>
        Trending Work
      </Header>
      <Card.Group itemsPerRow={2} className="goodCardGroup">
        {content.projects.map((project, index) => (
          <WorkCardWithModal project={project} key={index} />
        ))}
      </Card.Group>
    </div>
  );
};

export default TrendingWork;
